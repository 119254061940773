"use client";

import { Text } from "@Components/Typography";

export const NotFound: React.FC = () => {
    return (
        <main className="flex w-full flex-col overflow-hidden">
            <Text tag="p" align="center">
                This page could not be found. Go back to the chat page
            </Text>
        </main>
    );
};
